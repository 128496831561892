import { StyleSheet, Font } from "@react-pdf/renderer";

import CalibriFont from "../fonts/Calibri_Regular.ttf";

Font.register({
  family: "Calibri",
  src: CalibriFont,
});

// Create styles1
const styles1 = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingLeft: 35,
    fontSize: 10.5,
    lineHeight: 1.5,
    fontFamily: "Calibri",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginLeft: 10,
    paddingLeft: 10,
    marginRight: 45,
  },
  section1: {
    // flexWrap: "wrap",
    flexGrow: 1,
  },
  line: {
    margin: 10,
    marginRight: 45,
    borderColor: "black",
    borderBottom: 1.5,
  },
  name: {
    fontSize: 15,
  },
  headings: {
    // marginTop: 10,
    marginLeft: 10,
    paddingLeft: 10,
    fontSize: 12.5,
  },
  timeperiod: {
    marginLeft: "60%",
    marginRight: 45,
  },
  elementend: {
    marginBottom: 10,
  },
});

// Create styles2
const styles2 = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingLeft: 35,
    fontSize: 10.5,
    lineHeight: 1.5,
    fontFamily: "Calibri",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    // alignContent: "center",
    // alignItems: "baseline",
    marginLeft: 10,
    paddingLeft: 10,
    marginRight: 45,
  },
  section1: {
    // textAlign: "center",
    // flexWrap: "wrap",
    // flexGrow: 1,
  },
  section2: {
    // flexGrow: 1,
    // flexWrap: "wrap",
  },
  section3: {
    paddingLeft: 100,
  },
  line: {
    margin: 10,
    marginRight: 45,
    borderColor: "black",
    borderBottom: 1.5,
  },
  name: {
    fontSize: 15,
  },
  headings: {
    // marginTop: 10,
    // marginLeft: 10,
    // paddingLeft: 10,
    fontSize: 12.5,
  },
  // timeperiod: {
  //   marginLeft: "60%",
  //   marginRight: 45,
  // },
  elementend: {
    marginBottom: 10,
  },
});

export { styles1, styles2 };
