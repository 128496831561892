import React, { Component } from "react";
import { connect } from "react-redux";
// import _ from "lodash";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";

import Resume from "./Resume";
import "./Success.css";
import { uploadResume } from "../services/Resume.service";
import { toast, ToastContainer } from "react-toastify";

class Success extends Component {
  constructor(props) {
    super(props);

    this.state = { showAlert: false };

    this.generatePDFDocument = this.generatePDFDocument.bind(this);
    this.handleFinalize = this.handleFinalize.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  generatePDFDocument = async () => {
    const { personalDetails } = this.props;
    const { name } = personalDetails;
    const blob = await pdf(<Resume props={this.props} />).toBlob();
    let file = new File([blob], `${name}.pdf`);
    const formData = new FormData();
    formData.append("pdf", file);

    uploadResume(formData)
      .then((res) => {
        this.onDownload();
        toast.success(res.message || 'Successfully uploaded and downloaded resume. ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      })
      .catch((error) => {
        toast.error(error.message || 'Failed to upload resume.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        })
      });
  };

  handleFinalize = () => {
    this.generatePDFDocument();
  };

  // shouldComponentUpdate(nextProps) {
  //   return this.props.step !== nextProps.step;
  // }

  onDownload = async () => {
    const { personalDetails } = this.props;
    const { name } = personalDetails;

    const blob = await pdf(<Resume props={this.props} />).toBlob();
    saveAs(blob, `${name}`);
    this.setState({ showAlert: false });
  };

  onConfirm() {
    this.setState({ showAlert: false });
  }

  render() {
    const { showAlert } = this.state;
    const { step } = this.props;
    return (
      <>
        <div className="col-lg-12 text-center">
          <button
            className="styled-btn"
            onClick={this.handleFinalize}
            disabled={!(step === 4)}
            style={{
              visibility: step === 4 ? 'visible' : 'hidden'
            }}
          >
            Finalize
          </button>
        </div>
        <PDFViewer className="pdf-viewer" showToolbar={false}>
          <Resume props={this.props} />
        </PDFViewer>
        {/* <BlobProvider document={<Resume props={this.props} />}>
          {({ url, error }) => {
            if (error) {
              return <div>{error.message || 'Failed to load PDF'}</div>
            }
            return <embed
              src={url + "#toolbar=0"}
              type="application/pdf"
              // height={700}
              // width={500}
              style={{
                // pointerEvents: 'none'
                height:'90%',
                width: '100%'
              }}
            />
          }}
        </BlobProvider> */}
        {showAlert && (
          <SweetAlert
            success
            title="Resume Created Successfully!"
            onConfirm={this.onConfirm}
            customButtons={
              <React.Fragment>
                <button className="styled-btn" onClick={this.onDownload}>
                  Download
                </button>
                <button className="styled-btn" onClick={this.onConfirm}>
                  Close
                </button>
              </React.Fragment>
            }
          ></SweetAlert>
        )}
        <ToastContainer autoClose={2000} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // window.location.reload(false);
  return state;
};

export default connect(mapStateToProps, {})(Success);

/* <PDFDownloadLink
          document={<Resume2 props={this.props} />}
          fileName={`${this.props.personalDetails.name}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink> */
