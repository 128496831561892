import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CModal, CModalBody, CModalFooter, CModalHeader, CRow, CSpinner } from "@coreui/react";
import React from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { emailValidation, mobileNumberValidation } from "../helpers/common";
import { updateMyProfileDetails } from "../services/Me.service";
import DefaultProfile from "./Common/DefaultProfile";
import { TOASTER_STYLE } from "./constants";
import { userAuthDetailsAction } from "../store/actions";
class Profile extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
    this.state = {
      userMobileNumber: '',
      signupEmail: '',
      userName: '',
      profileImage: '',
      formErrors: {
        userMobileNumber: '',
        userName: '',
      },
      loader: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  componentDidMount() {
    const { profile = {} } = this.props;
    if (!profile || !Object.keys(profile).length) {
      return;
    } else {
      this.setState({
        userMobileNumber: this.props.profile.mobile,
        signupEmail: this.props.profile.email,
        userName: this.props.profile.name,
        profileImage: this.props.profile.profile_image
      })
    }
  }

  handleOnChange(event) {
    event.preventDefault();
    const { value, name } = event.target;
    if (name === 'profileImage') {
      const file = event?.target?.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size < 5e6) {
          this.setState({
            ...this.state,
            [name]: reader?.result ?? ''
          })
        } else {
          toast.error('File size is should be less than 5MB', {
            position: 'bottom-right'
          })
        }
      };
      return;
    }
    let updatedValue = value;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'userMobileNumber': {
        updatedValue = value.replace(/[^0-9.]/g, "");
        if (!mobileNumberValidation(updatedValue)) {
          formErrors.userMobileNumber = true;
        } else {
          formErrors.userMobileNumber = false;
        }
        break;
      }
      case 'signupEmail': {
        if (!emailValidation(updatedValue)) {
          formErrors.signupEmail = true;
        } else {
          formErrors.signupEmail = false;
        }
        break;
      }
      default:
        break;
    }
    this.setState({
      ...this.state,
      formErrors
    })

    this.setState({
      ...this.state,
      [name]: updatedValue,
    });
  }

  handleSubmit() {
    try {
      this.setState({
        loader: true
      })
      updateMyProfileDetails({
        mobile: this.state.userMobileNumber,
        name: this.state.userName,
        profile_image: this.state.profileImage
      }).then((res) => {
        this.props.userAuthDetailsAction({
          ...this.props.userAuthDetails,
          user: res.data
        })
        this.handleClose();
      }).catch((error) => {
        if (error.message?.includes('expired')) {
          this.props.history.push('/');
        }
        toast.error(error.message || 'Failed to save profile details.', TOASTER_STYLE)
      }).finally(() => {
        this.setState({
          loader: false
        })
      })
    } catch (error) {
      toast.error(error.message || 'Failed to save profile details.', TOASTER_STYLE)
    }
  }

  renderBody() {
    const {
      userMobileNumber,
      userName,
      signupEmail,
      profileImage,
      formErrors
    } = this.state;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <div className="signup-form">
                      <div className="right-side">
                        {(
                          <Fragment>
                            <div className="form-section">
                              <div className="form-group">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Mobile Number
                                </label>
                                <input
                                  type="text"
                                  name="userMobileNumber"
                                  placeholder="Mobile number"
                                  className="form-input form-control"
                                  id="userMobileNumber"
                                  onChange={this.handleOnChange}
                                  value={userMobileNumber}
                                  maxLength="10"
                                />
                                {formErrors.userMobileNumber && (
                                  <span className="error-message">
                                    Please enter valid mobile number
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="userName"
                                  placeholder="Name"
                                  className="form-input form-control"
                                  id="userName"
                                  onChange={this.handleOnChange}
                                  value={userName}
                                />
                                {formErrors.userName && (
                                  <span className="error-message">
                                    Please enter name
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="signupEmail"
                                  placeholder="abc@gmail.com"
                                  id="signupEmail"
                                  className="form-input form-control"
                                  onChange={this.handleOnChange}
                                  disabled={true}
                                  value={signupEmail}
                                />
                                {formErrors.signupEmail && (
                                  <span className="error-message">
                                    Please enter valid email
                                  </span>
                                )}
                              </div>
                              <div className="avatar form-group">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Profile Image
                                </label>
                                <div className="clinic-upload-main-view">
                                  <div className="image-cirle-view">
                                    {!profileImage ? <DefaultProfile /> : <img
                                      src={profileImage}
                                      className="image"
                                      alt='profileImage'
                                    />
                                    }
                                    <div className="file-operation-text-container">
                                      <input
                                        accept="image/*"
                                        type="file"
                                        id="profileImage"
                                        name="profileImage"
                                        className="image-input"
                                        onChange={this.handleOnChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }

  render() {
    return (
      <div>
        <CModal
          show={this.props.open}
          onClose={this.handleClose}
          closeOnBackdrop={false}
          backdrop={false}
        >
          <CModalHeader>Profile Details</CModalHeader>
          <form>
            <CModalBody>
              {this.renderBody()}
            </CModalBody>
            <CModalFooter>
              <CButton
                color="primary"
                onClick={this.handleSubmit}
                disabled={this.state.loader}
              >
                {this.state.loader ? <CSpinner /> : 'Update Profile'}
              </CButton>{' '}
              <CButton
                color="secondary"
                onClick={this.handleClose}
                disabled={this.state.loader}
              >
                {this.state.loader ? <CSpinner /> : 'Cancel'}
              </CButton>
            </CModalFooter>
          </form>
        </CModal>
        <ToastContainer autoClose={2000} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails } = state;
  return { userAuthDetails };
};

export default connect(mapStateToProps, {
  userAuthDetailsAction
})(Profile);
