import React from "react";
import { Document, Page, Text, View, Link } from "@react-pdf/renderer";

import { styles1, styles2 } from "./ResumeStyles";

export default function Resume1(props) {
  const {
    personalDetails,
    educationalDetails,
    internshipDetails,
    projectDetails,
    selectedTemplate,
  } = props.props;
  const {
    name,
    email,
    phone,
    position,
    branch,
    blog,
    objective,
  } = personalDetails;
  const {
    degree,
    institute,
    intermediate,
    college,
    school_course,
    school,
    passOut1,
    passOut2,
    passOut3,
    cgpa1,
    cgpa2,
    cgpa3,
  } = educationalDetails;
  const { internshipDetailsArray } = internshipDetails;
  const { projectDetailsArray } = projectDetails;
  const { id } = selectedTemplate;

  return (
    <Document>
      {/* Resume 1 starts */}

      {id === 1 && (
        <Page size="A4" style={styles1.page}>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text style={styles1.name}>{name}</Text>
              <Text>{position}</Text>
              <Text>{branch}</Text>
            </View>
            <View style={styles1.section1}>
              <Text>Email : {email}</Text>
              <Text>Mobile : {phone}</Text>
              <Text>Blog : {blog}</Text>
            </View>
          </View>
          <Text style={styles1.line}></Text>

          <Text style={styles1.headings}>OBJECTIVE :</Text>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text style={styles1.elementend}>{objective}</Text>
            </View>
          </View>

          <Text style={styles1.headings}>EDUCATIONAL PROFILE :</Text>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{degree}</Text>
            </View>
            <View style={styles1.section1}>
              <Text style={styles1.timeperiod}>
                [{passOut2} - {passOut1}]
              </Text>
            </View>
          </View>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{institute}</Text>
              <Text style={styles1.elementend}>CGPA: {cgpa1}</Text>
            </View>
          </View>

          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{intermediate}</Text>
            </View>
            <View style={styles1.section1}>
              <Text style={styles1.timeperiod}>
                [{passOut3} - {passOut2}]
              </Text>
            </View>
          </View>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{college}</Text>
              <Text style={styles1.elementend}>CGPA: {cgpa2}</Text>
            </View>
          </View>

          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{school_course}</Text>
            </View>
            <View style={styles1.section1}>
              <Text style={styles1.timeperiod}>[{passOut3}]</Text>
            </View>
          </View>
          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>{school}</Text>
              <Text style={styles1.elementend}>CGPA: {cgpa3}</Text>
            </View>
          </View>

          <Text style={styles1.headings}>INTERNSHIP :</Text>
          {internshipDetailsArray.map((data, index) => {
            const {
              title,
              duration_from,
              duration_to,
              guide_name,
              designation,
              company,
              description,
              reference,
            } = data;
            return (
              <View key={index}>
                <View style={styles1.section}>
                  <View style={styles1.section1}>
                    <Text>{title}</Text>
                  </View>
                  <View style={styles1.section1}>
                    <Text style={styles1.timeperiod}>
                      [{duration_from} - {duration_to}]
                    </Text>
                  </View>
                </View>
                <View style={styles1.section}>
                  <View style={styles1.section1}>
                    <Text>
                      Guide: {guide_name}, {designation} - {company}
                    </Text>
                    <Text>Description: {description}</Text>
                    <Text>Technologies Used:</Text>
                    <Text style={styles1.elementend}>
                      Reference: <Link src={reference}>{reference}</Link>
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

          <Text style={styles1.headings}>PROJECTS :</Text>
          {projectDetailsArray.map((data, index) => {
            const {
              title,
              description,
              technologies,
              duration_from,
              duration_to,
              reference,
            } = data;
            return (
              <View key={index}>
                <View style={styles1.section}>
                  <View style={styles1.section1}>
                    <Text>{title}</Text>
                  </View>
                  <View style={styles1.section1}>
                    <Text style={styles1.timeperiod}>
                      [{duration_from} - {duration_to}]
                    </Text>
                  </View>
                </View>
                <View style={styles1.section}>
                  <View style={styles1.section1}>
                    <Text>Description: {description}</Text>
                    <Text>Technologies Used: {technologies}</Text>
                    <Text style={styles1.elementend}>
                      Reference: <Link src={reference}>{reference}</Link>
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>Declaration:</Text>
              <Text style={styles1.elementend}>
                I hereby declare that all the above information provided by me
                are correct and up to date. I completely bear the responsibility
                for any incorrect data provided.
              </Text>
            </View>
          </View>

          <View style={styles1.section}>
            <View style={styles1.section1}>
              <Text>Place: </Text>
              <Text>Date:</Text>
              <Text style={{ paddingLeft: "50%", marginRight: "100%" }}>
                {name}
              </Text>
            </View>
          </View>
        </Page>
      )}

      {/* Resume 1 ends */}

      {/* Resume 2 starts */}

      {id === 2 && (
        <Page size="A4" style={styles2.page}>
          <View style={styles2.section}>
            <View style={styles2.section1}>
              <Text style={styles2.name}>{name}</Text>
              <Text>{position}</Text>
              <Text>
                {email}, {blog}, {phone}
              </Text>
            </View>
          </View>
          <Text style={styles2.line}></Text>
          <View style={styles2.section}>
            <View style={styles2.section2}>
              <Text style={styles2.headings}>OBJECTIVE :</Text>
              <Text style={(styles2.elementend, { paddingLeft: 100 })}>
                {objective}
              </Text>
            </View>
          </View>

          <Text style={styles2.line}></Text>

          <View style={styles2.section}>
            <View style={styles2.section2}>
              <Text style={styles2.headings}>EDUCATIONAL PROFILE :</Text>
              <Text>
                {passOut2} - {passOut1}
              </Text>
              <View style={styles2.section3}>
                <Text>{degree}</Text>
                <Text>{institute}</Text>
                <Text style={styles2.elementend}>CGPA: {cgpa1}</Text>
              </View>

              <Text>
                {passOut3} - {passOut2}
              </Text>
              <View style={styles2.section3}>
                <Text>{intermediate}</Text>
                <Text>{college}</Text>
                <Text style={styles2.elementend}>CGPA: {cgpa2}</Text>
              </View>

              <Text>{passOut3}</Text>
              <View style={styles2.section3}>
                <Text>{school_course}</Text>
                <Text>{school}</Text>
                <Text style={styles2.elementend}>CGPA: {cgpa3}</Text>
              </View>
            </View>
          </View>

          <Text style={styles2.line}></Text>

          <View style={styles2.section}>
            <View style={styles2.section2}>
              <Text style={styles2.headings}>INTERNSHIP :</Text>
              {internshipDetailsArray.map((data, index) => {
                const {
                  title,
                  duration_from,
                  duration_to,
                  guide_name,
                  designation,
                  company,
                  description,
                  reference,
                  technologies,
                } = data;
                return (
                  <View key={index}>
                    <Text>
                      {duration_from} - {duration_to}
                    </Text>
                    <View style={styles2.section3}>
                      <Text>{title}</Text>
                      <Text>
                        Guide: {guide_name}, {designation} - {company}
                      </Text>
                      <Text>Description: {description}</Text>
                      <Text>Technologies Used: {technologies}</Text>
                      <Text style={styles2.elementend}>
                        Reference: <Link src={reference}>{reference}</Link>
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>

          <Text style={styles2.line}></Text>

          <View style={styles2.section}>
            <View style={styles2.section2}>
              <Text style={styles2.headings}>PROJECTS :</Text>
              {projectDetailsArray.map((data, index) => {
                const {
                  title,
                  description,
                  technologies,
                  duration_from,
                  duration_to,
                  reference,
                } = data;
                return (
                  <View key={index}>
                    <Text>
                      {duration_from} - {duration_to}
                    </Text>
                    <View style={styles2.section3}>
                      <Text>{title}</Text>

                      <Text>Description: {description}</Text>
                      <Text>Technologies Used: {technologies}</Text>
                      <Text style={styles2.elementend}>
                        Reference: <Link src={reference}>{reference}</Link>
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>

          <Text style={styles2.line}></Text>

          <View style={styles2.section}>
            <View>
              <Text>Declaration:</Text>
              <View style={styles2.section3}>
                <Text style={styles2.elementend}>
                  I hereby declare that all the above information provided by me
                  are correct and up to date. I completely bear the
                  responsibility for any incorrect data provided.
                </Text>
              </View>
            </View>
          </View>

          <View style={styles2.section}>
            <View style={{ paddingLeft: 20 }}>
              <Text>Place: </Text>
              <Text>Date:</Text>
              <View style={styles2.section3}>
                <Text style={{ paddingLeft: "50%", marginRight: "100%" }}>
                  {name}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}

      {/* Resume 2 starts */}
    </Document>
  );
}
