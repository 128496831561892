import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";

import "./UserAuth.css";
import { emailValidation } from "../../helpers/common";
import { forgetPassword } from "../../services/Auth.service";
import { toast, ToastContainer } from "react-toastify";
import { TOASTER_STYLE } from "../constants";
import { userAuthDetailsAction } from "../../store/actions";

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email_error: '',
      payload: {
        email: ''
      },
      loader: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    const { history, userAuthDetails } = this.props;
    if (userAuthDetails?.token) {
      history.push("/dashboard");
      return;
    }
  }

  validateForm() {
    const { email } = this.state.payload;
    let validationFailed = false;
    if (!email) {
      this.setState({
        email_error: 'Email is required.'
      });
      validationFailed = true;
    }
    if (!emailValidation(email)) {
      this.setState({
        email_error: 'Invalid Email'
      });
      validationFailed = true;
    }
    return validationFailed;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      return;
    }
    this.setState({ loader: true })
    forgetPassword(this.state.payload)
      .then((res) => {
        toast.success(res.message || 'Failed to reset password link.', TOASTER_STYLE);
        this.setState({ loader: false })
        this.props.toggleView('login')
      }).catch((err) => {
        toast.error(err.message || 'Failed to reset password link.', TOASTER_STYLE);
      }).finally(() => {
        this.setState({ loader: false })
      })
  }

  handleOnChange(e) {
    const { value, name } = e.target;

    switch (name) {
      case "email": {
        if (emailValidation(value)) {
          this.setState({ email_error: '' });
        } else {
          this.setState({ email_error: 'Invalid Email.' });
        }
        break;
      }
      default:
        break;
    }

    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      }
    });
  }

  render() {
    const {
      payload: {
        email,
      },
      email_error,
      loader
    } = this.state;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="cardpadding">
                  <CCardBody>
                    <div className="login-container">
                      <div className="login-form">
                        Enter the valid email to receive reset password link
                        <div className="form-group">
                          <br></br>
                          User Email*
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <i
                                  className="fa fa-user facolor"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Enter Email"
                              id="email"
                              value={email}
                              onChange={this.handleOnChange}
                            />
                          </div>
                          {email_error && (
                            <span className="error-message-text" style={{ color: "red" }}>
                              {email_error}
                            </span>
                          )}
                        </div>
                        &nbsp;
                        <CRow>
                          <CCol xs="8">
                            <div
                              className="form-group"
                              onClick={(e) => this.handleSubmit(e)}
                            >
                              <button
                                className="reset-button"
                                disabled={loader}
                              >
                                Send Link
                                {loader && (
                                  <span className="loader">
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </span>
                                )}
                              </button>
                            </div>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails, stepsCompleted } = state;
  return { userAuthDetails, step: stepsCompleted?.step };
};

export default connect(mapStateToProps, {
  userAuthDetailsAction
})(withRouter(ForgetPassword));
