export const firebaseConfig = {
  apiKey: "AIzaSyAdCeWRt7ha69tt0Irm9gognuiB84_3lck",
  authDomain: "upladder-createresume.firebaseapp.com",
  databaseURL: "https://upladder-createresume.firebaseio.com",
  projectId: "upladder-createresume",
  storageBucket: "upladder-createresume.appspot.com",
  messagingSenderId: "720783646022",
  appId: "1:720783646022:web:d2655d4ac49a2aa8d39947",
  measurementId: "G-ENGJF5SRR7",
};

export const TOASTER_STYLE = {
  position: 'bottom-right' 
}

export const SAVE_TIME_INTERVAL = 3000;

export const UserTypes = {
  USER: 'user',
  ADMIN: 'admin'
}
