import React, { Component, Fragment } from "react";
import * as firebase from "firebase";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
  CLink
} from "@coreui/react";
import { emailValidation, mobileNumberValidation, passwordValidation } from "../../helpers/common";
import { checkMobileExist, signupUser } from "../../services/Auth.service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import Recaptcha from "react-google-recaptcha";
class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      userMobileNumber: "",
      signupEmail: "",
      signupPassword: "",
      userName: "",
      signupRepeatPassword: "",
      firebaseApp: null,
      showOtpScreen: false,
      showUserExists: false,
      showRegisterForm: true,
      showSignupLoader: false,
      showOtpLoader: false,
      otpInput: "",
      userData: {},
      formErrors: {
        signupEmail: '',
        signupPassword: '',
        userMobileNumber: '',
        userName: '',
        signupRepeatPassword: ''
      },
      appVerifier: ''
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
  }

  registerUser(event) {
    event.preventDefault();
    const thisRef = this;
    // const { setSignupFilledDetails } = this.props;
    const {
      signupEmail,
      userMobileNumber,
      signupPassword,
      signupRepeatPassword,
      userName,
    } = this.state;
    let formErrors = {};
    if (!userMobileNumber || userMobileNumber.length !== 10) {
      formErrors.userMobileNumber = true;
    } 
    if (!userName) {
      formErrors.userName = true;
    } 
    if (!signupEmail || !emailValidation(signupEmail)) {
      formErrors.signupEmail = true;
    } 
    if (!signupPassword || !passwordValidation(signupPassword)) {
      formErrors.signupPassword = true;
    } 
    if (
      !signupRepeatPassword ||
      signupRepeatPassword !== signupPassword
    ) {
      formErrors.signupRepeatPassword = true;
    }
    if (Object.keys(formErrors).find((key) => !!formErrors[key])) {
      this.setState({
        ...this.state,
        formErrors
      });
      return;
    } else {
      thisRef.setState({
        showSignupLoader: true,
      });

      checkMobileExist({ mobile: userMobileNumber })
        .then(() => {
            this.setState({
              userData: {
                mobile: userMobileNumber,
                email: signupEmail,
                password: signupPassword,
                name: userName,
              },
            });

            const phoneNumber = `+91${userMobileNumber}`;
            let appVerifier;
            if (!this.state.appVerifier){
              appVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                { size: "invisible" }
              );
              this.setState({
                ...this.state,
                appVerifier
              })
            }
            firebase
              .auth()
              .signInWithPhoneNumber(phoneNumber, this.state.appVerifier ?? appVerifier)
              .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                thisRef.setState({
                  showOtpScreen: true,
                  showRegisterForm: false,
                  showUserExists: false,
                  showSignupLoader: false,
                });
                // setSignupFilledDetails(signUpData);
              })
              .catch(function (error) {
                thisRef.setState({
                  showUserExists: false,
                  showRegisterForm: true,
                  showOtpScreen: false,
                  showSignupLoader: false,
                });
                toast.error(error.message ?? 'Failed to signup', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
                });
              });
        }).catch((error) => {
          toast.error(error.message ?? 'Failed to signup', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
          });
          thisRef.setState({
            showUserExists: false,
            showRegisterForm: true,
            showOtpScreen: false,
            showSignupLoader: false,
          });
        })
    }
  }

  handleOnChange(event) {
    event.preventDefault();
    const { value, name } = event.target;
    let updatedValue = value;
    let formErrors = this.state.formErrors;
    switch(name) {
      case 'userMobileNumber': {
        updatedValue = value.replace(/[^0-9.]/g, "");
        if (!mobileNumberValidation(updatedValue)) {
          formErrors.userMobileNumber = true;
        } else {
          formErrors.userMobileNumber = false;
        }
        break;
      }
      case 'signupEmail': {
        if (!emailValidation(updatedValue)) {
          formErrors.signupEmail = true;
        } else {
          formErrors.signupEmail = false;
        }
        break;
      }
      case 'signupPassword': {
        if (!passwordValidation(updatedValue)) {
          formErrors.signupPassword = true;
        } else {
          formErrors.signupPassword = false;
        }
        break;
      }
      case 'signupRepeatPassword': {
        if (updatedValue !== this.state.signupPassword) {
          formErrors.signupRepeatPassword = true;
        } else {
          formErrors.signupRepeatPassword = false;
        }
        break;
      }
      default:
        break;
    }
    this.setState({
      ...this.state,
      formErrors
    })

    this.setState({
      ...this.state,
      [name]: updatedValue,
    });
  }

  verifyOTP(event) {
    event.preventDefault();
    const thisRef = this;
    const { otpInput, userData } = this.state;
    // const { userData } = this.props;
    // const {toggleView}=this.props;
    if (!otpInput || otpInput.length !== 6) {
      document.getElementById("otpInput").focus();
      thisRef.setState({
        showOtpLoader: false,
      });
      return;
    } else {
      thisRef.setState({
        showOtpLoader: true,
      });
      window.confirmationResult
        .confirm(otpInput)
        .then(function (result) {
          //   User signed in successfully.
          signupUser(userData)
            .then((data) => {
              thisRef.setState({
                showOtpLoader: false,
                showUserExists: false,
                showRegisterForm: false,
                showOtpScreen: false,
                showVerifiedSuccessFully: true,
              });
              toast.success('Sign up successful', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
              });
            }).catch((error) => {
              toast.error(error.message ?? 'Failed to signup', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
              });
              thisRef.setState({
                showUserExists: false,
                showRegisterForm: true,
                showOtpScreen: false,
                showSignupLoader: false,
              });
            })
          // ...
        })
        .catch((error) => {
          // console.error(error);
          toast.error(error.message ?? 'Failed to signup', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
          });
          thisRef.setState({
            showOtpLoader: false
          });
        });
    }
  }

  render() {
    const { toggleView } = this.props;
    const {
      showOtpScreen,
      showUserExists,
      showRegisterForm,
      userMobileNumber,
      showSignupLoader,
      showOtpLoader,
      showVerifiedSuccessFully,
      formErrors
    } = this.state;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <div className="signup-form">
                      <div className="right-side">
                        {showRegisterForm && (
                          <Fragment>
                            <div className="form-section">
                              <form action="#" onSubmit={this.registerUser}>
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Mobile Number
                                  </label>
                                  <input
                                    type="text"
                                    name="userMobileNumber"
                                    placeholder="Mobile number"
                                    className="form-input form-control"
                                    id="userMobileNumber"
                                    onChange={this.handleOnChange}
                                    value={userMobileNumber}
                                    maxLength="10"
                                  />
                                  {formErrors.userMobileNumber && (
                                      <span className="error-message">
                                        Please enter valid mobile number
                                      </span>
                                    )}
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="userName"
                                    placeholder="Name"
                                    className="form-input form-control"
                                    id="userName"
                                    onChange={this.handleOnChange}
                                  />
                                  {formErrors.userName && (
                                      <span className="error-message">
                                        Please enter name
                                      </span>
                                    )}
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    name="signupEmail"
                                    placeholder="abc@gmail.com"
                                    id="signupEmail"
                                    className="form-input form-control"
                                    onChange={this.handleOnChange}
                                  />
                                  {formErrors.signupEmail && (
                                      <span className="error-message">
                                        Please enter valid email
                                      </span>
                                    )}
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    name="signupPassword"
                                    placeholder="Password"
                                    id="signupPassword"
                                    className="form-input form-control"
                                    onChange={this.handleOnChange}
                                  />
                                  {formErrors.signupPassword && (
                                      <span className="error-message">
                                        Password should contain 8 characters, one alphabet, one digit and one special character.
                                      </span>
                                    )}
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Repeat Password
                                  </label>
                                  <input
                                    type="password"
                                    name="signupRepeatPassword"
                                    placeholder="Confirm Password"
                                    id="signupRepeatPassword"
                                    className="form-input form-control"
                                    onChange={this.handleOnChange}
                                  />
                                  {formErrors.signupRepeatPassword && (
                                      <span className="error-message">
                                        Passwords are not matching
                                      </span>
                                    )}
                                </div>

                                <div
                                  ref={(ref) =>
                                    (this.recaptchaWrapperRef = ref)
                                  }
                                >
                                  <div id="recaptcha-container">
                                    {/* <Recaptcha
                                      id="recaptcha-container"
                                      sitekey="6Lff_8cZAAAAALonO6NaWL6mmxZJUL_AfqnFl_53"
                                      render="explicit"
                                      onChange={this.verifyCallback}
                                      asyncScriptOnLoad={this.loadCallback}
                                    /> */}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <button
                                    className="login-button btn1"
                                    // onClick={this.registerUser}
                                    disabled={showSignupLoader}
                                    type="submit"
                                  >
                                    Signup
                                    {showSignupLoader && (
                                      <span className="loader">
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </form>
                              <div className="bottom-line"></div>
                              <div className="forgot-password-section">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggleView("login")}
                                >
                                  Already member ?
                                </span>
                              </div>
                            </div>
                          </Fragment>
                        )}
                        {showOtpScreen && (
                          <Fragment>
                            <div className="mentor-otp-container">
                              <h4 className="heading">
                                Phone Number verification
                              </h4>
                              <h4 className="success-message">
                                We have sent One Time Password (OTP) to your
                                mobile Number
                                <span className="mobile-number">
                                  {userMobileNumber}
                                </span>
                              </h4>
                              <div className="otp-details-form">
                                <form action="#">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="otp-input-container form-control"
                                      name="otpInput"
                                      id="otpInput"
                                      onChange={this.handleOnChange}
                                    />
                                  </div>

                                  <button
                                    className="styled-btn"
                                    onClick={this.verifyOTP}
                                    disabled={showOtpLoader}
                                  >
                                    Submit
                                    {showOtpLoader && (
                                      <span className="loader">
                                        <i className="fa fa-spin fa-spinner"></i>
                                      </span>
                                    )}
                                  </button>
                                </form>
                              </div>
                            </div>
                          </Fragment>
                        )}
                        {showUserExists && (
                          <Fragment>
                            <div className="mentor-user-exitst-container">
                              <h4 className="heading">
                                Oops! You already have an account with us!
                              </h4>
                              <h4 className="success-message">
                                Please{" "}
                                <CLink onClick={() => toggleView("login")}>
                                  Login here
                                </CLink>
                              </h4>
                            </div>
                          </Fragment>
                        )}
                        {showVerifiedSuccessFully && (
                          <Fragment>
                            <div className="mentor-user-exitst-container">
                              <h4 className="heading">
                                Your mobile number verified successfully!
                              </h4>
                              <h4 className="success-message">
                                Please{" "}
                                <CLink onClick={() => toggleView("login")}>
                                  Login here
                                </CLink>
                              </h4>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
                <CCard className="text-white  py-5 d-md-down-none maincards">
                  <CCardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>
                        Land your dream job with the perfect resume employers
                        are looking for!
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

export default SignUp;
