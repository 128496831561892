import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { personalDetailsAction } from "../store/actions";
import { SAVE_TIME_INTERVAL } from "./constants";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      blog: "",
      position: "",
      branch: "",
      objective: "",
    };

    this.inputChange = this.inputChange.bind(this);
    this.updateReduxState = this.updateReduxState.bind(this);
    this.refreshIntervalId = undefined;
  }

  updateReduxState() {
    const data = _.omit(this.state, ["emailWarning"]);
    this.props.personalDetailsAction(data);
  }
  componentDidMount() {
    const { personalDetails } = this.props;

    if (!_.isEmpty(personalDetails)) {
      this.setState(personalDetails);
    }
    this.refreshIntervalId = setInterval(this.updateReduxState, SAVE_TIME_INTERVAL);
  }

  componentWillUnmount() {
    const data = _.omit(this.state, ["emailWarning"]);
    this.props.personalDetailsAction(data);
    clearInterval(this.refreshIntervalId);
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      name,
      email,
      phone,
      position,
      branch,
      blog,
      objective,
    } = this.state;

    return (
      <div className="form-container">
        <h1 className="mb-5">Personal Details</h1>
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input
          
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="full name"
            onChange={(e) => this.inputChange(e)}
            value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="position">Position</label>
          <input
            type="text"
            className="form-control"
            id="position"
            name="position"
            placeholder="present job"
            onChange={(e) => this.inputChange(e)}
            value={position}
          />
        </div>
        <div className="form-group">
          <label htmlFor="branch">Branch</label>
          <input
            type="text"
            className="form-control"
            id="branch"
            name="branch"
            placeholder="example:Computer Science Engineering"
            onChange={(e) => this.inputChange(e)}
            value={branch}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="abcd@gmail.com"
            onChange={(e) => this.inputChange(e)}
            value={email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Mobile Number</label>
          <input
            type="number"
            className="form-control"
            id="phone"
            name="phone"
            placeholder="phone number"
            onChange={(e) => this.inputChange(e)}
            value={phone}
          />
        </div>
        <div className="form-group">
          <label htmlFor="blog">Blog URL</label>
          <input
            type="text"
            placeholder="www.google.co.in"
            className="form-control"
            id="blog"
            name="blog"
            onChange={(e) => this.inputChange(e)}
            value={blog}
          />
        </div>
        <div className="form-group">
          <label htmlFor="objective">Objective for Resume</label>
          <textarea
            rows="4"
            type="text"
            placeholder="type text"
            className="form-control"
            id="objective"
            name="objective"
            onChange={(e) => this.inputChange(e)}
            value={objective}
          />
        </div>

        <br />

        {/* <div className="text-right">
          <button className="btn btn-primary" onClick={this.continue}>
            Continue
          </button>
        </div> */}

        {/* <button className="on-save" onClick={this.onSave}>
          Save
        </button> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { personalDetailsAction })(
  PersonalDetails
);
