import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AuthPage from "./UserAuth/AuthPage";
import Dashboard from "./Dashboard";
import "./App.css";
import { axios, setAuthorizationHeader } from "../utils/axios.util";
import { connect } from "react-redux";
import {userAuthDetailsAction} from '../store/actions'
import ResetPassword from "./ResetPassword/ResetPassword";
import AdminDashboard from "./AdminDashboard/AdminDashboard";

class App extends React.Component {
  constructor() {
    super();
    this.state = { step: 1 };
    this.formChange = this.formChange.bind(this);
    this.plugInterceptors = this.plugInterceptors.bind(this);
  }

  formChange = (step) => {
    this.setState({ step });
  };

  componentDidMount() {
    this.plugInterceptors();
  }
  

  plugInterceptors() {
    const thisRef = this;

    // Request interceptor
    axios.interceptors.request.use(
      function (config) {
       return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const updatePops = () => {
      thisRef.props.userAuthDetailsAction({});
      window.location.href = '/'
    }
    // Response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.message?.includes('401')) {
          updatePops();
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    return (
      <Router>
        <div>
          <Route path="/" exact component={AuthPage} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/admin/dashboard" exact component={AdminDashboard} />
          <Route path="/resetPassword" exact component={ResetPassword} />
        </div>
      </Router>
    );
  }
}


const mapStateToProps = (state) => {
  const { userAuthDetails } = state;
  const token = userAuthDetails?.token;
  setAuthorizationHeader(token)
  return { userAuthDetails, ...state};
};


export default connect(mapStateToProps, {
  userAuthDetailsAction
})(App);
