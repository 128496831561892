import { CCard, CCardBody, CCardHeader, CCol, CContainer, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow } from "@coreui/react";
import React from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getDashboardCards } from "../../services/Dashboard.service";
import { userAuthDetailsAction } from "../../store/actions";
import { TOASTER_STYLE } from "../constants";
import Profile from "../Profile";

class AdminDashBoard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      openProfile: false,
      users: 0,
      resumes: 0
    }
    this.logout = this.logout.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }

  componentDidMount() {
    const { userAuthDetails, history } = this.props;
    if (!userAuthDetails?.token) {
      history.push("/");
      return;
    }
    getDashboardCards()
      .then((res) => {
        if (res.data) {
          this.setState({
            users: res?.data?.userCount ?? 0,
            resumes: res?.data?.userResumeCount ?? 0
          })
        }
      })
      .catch((e) => {
        toast.error(e.message || 'Failed to fetch dashboard cards.', TOASTER_STYLE);
      })
  }

  logout = () => {
    this.props.userAuthDetailsAction({});
    this.props.history.push("/");
  }

  handleProfile = () => {
    this.setState({
      ...this.state,
      openProfile: !this.state.openProfile,
    });
    }

  render() {
    return (
      <div className="App">
        <div className="row">
          <div className="col-lg-12 text-center" style={{ color: "white", display: "flex", justifyContent:"space-between"}}>
            <h1 style={{flexGrow: 4}}>Create My Resume</h1>
            <CDropdown>
              <CDropdownToggle className=".image-cirle-view">
                {this.props?.userAuthDetails?.user?.name ?? 'User'}
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem className="user-item" onClick={this.handleProfile}>Profile</CDropdownItem>
                <CDropdownItem className="user-item" onClick={this.logout}>Logout</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
        </div>
        <div className="row dashboard-container">
          <CContainer fluid>
          <CRow>
            <CCol sm="4">
              <CCard>
                <CCardHeader>
                  Users Registered
                </CCardHeader>
                <CCardBody>
                  {'Total'}: {this.state.users}
                </CCardBody>
                {/* <CCardFooter>
                  <CLink>View</CLink>
                </CCardFooter> */}
              </CCard>
            </CCol>
            <CCol sm="4">
              <CCard>
                <CCardHeader>
                  Resumes uploaded
                </CCardHeader>
                <CCardBody>
                  Total: {this.state.resumes}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
        </div>
        <Profile open={this.state.openProfile} onClose={this.handleProfile} profile={this?.props?.userAuthDetails?.user} history={this.props.history}/>
        <ToastContainer autoClose={2000} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails, stepsCompleted } = state;
  return { userAuthDetails, step: stepsCompleted?.step };
};

export default connect(mapStateToProps, {
  userAuthDetailsAction
})(AdminDashBoard);
