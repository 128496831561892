export const ACTION_TYPES = {
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  EDUCATIONAL_DETAILS: 'EDUCATIONAL_DETAILS',
  INTERNSHIP_DETAILS: "INTERNSHIP_DETAILS",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  USER_AUTH_DETAILS: "USER_AUTH_DETAILS",
  SELECTED_TEMPLATE: "SELECTED_TEMPLATE",
  PDF_UPLOAD: "PDF_UPLOAD",
  STEPS_COMPLETED: "STEPS_COMPLETED"
}

export const personalDetailsAction = (data) => {
  return {
    type: ACTION_TYPES.PERSONAL_DETAILS,
    payload: data,
  };
};

export const educationalDetailsAction = (data) => {
  return {
    type: ACTION_TYPES.EDUCATIONAL_DETAILS,
    payload: data,
  };
};

export const internshipDetailsAction = (data) => {
  return {
    type: ACTION_TYPES.INTERNSHIP_DETAILS,
    payload: data,
  };
};

export const projectDetailsAction = (data) => {
  return {
    type: ACTION_TYPES.PROJECT_DETAILS,
    payload: data,
  };
};

export const userAuthDetailsAction = (data) => {
  return {
    type: ACTION_TYPES.USER_AUTH_DETAILS,
    payload: data,
  };
};

export const selectTemplateAction = (data) => {
  return {
    type: ACTION_TYPES.SELECTED_TEMPLATE,
    payload: data,
  };
};

export const pdfUploadAction = (data) => {
  return {
    type: ACTION_TYPES.PDF_UPLOAD,
    payload: data,
  };
};

export const stepsCompletedAction = (data) => {
  return {
    type: ACTION_TYPES.STEPS_COMPLETED,
    payload: data,
  };
};
