import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { internshipDetailsAction } from "../store/actions";
import { SAVE_TIME_INTERVAL } from "./constants";

export class InternshipDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      internshipDetailsArray: [
        {
          title: "",
          description: "",
          guide_name: "",
          designation: "",
          company: "",
          duration_from: "",
          duration_to: "",
          reference: "",
          technologies: "",
        },
      ],
    };
    this.inputChange = this.inputChange.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.refreshIntervalId = undefined;
    this.updateReduxState = this.updateReduxState.bind(this);
  }

  updateReduxState() {
    const data = this.state;
    this.props.internshipDetailsAction(data);
  }

  componentDidMount() {
    const { internshipDetails } = this.props;

    if (!_.isEmpty(internshipDetails)) {
      this.setState(internshipDetails);
    }
    this.refreshIntervalId = setInterval(this.updateReduxState, SAVE_TIME_INTERVAL);
  }

  componentWillUnmount() {
    const data = this.state;
    this.props.internshipDetailsAction(data);
    clearInterval(this.refreshIntervalId);
  }

  inputChange = (e, index) => {
    let { internshipDetailsArray } = this.state;
    internshipDetailsArray[index][e.target.name] = e.target.value;
    this.setState({
      internshipDetailsArray,
    });

    // this.setState({
    //   [e.target.name]: e.target.value,
    // });
  };

  addField(e) {
    e.preventDefault();
    const add = {
      title: "",
      description: "",
      guide_name: "",
      designation: "",
      company: "",
      duration_from: "",
      duration_to: "",
      reference: "",
      technologies: "",
    };
    this.setState({
      internshipDetailsArray: [...this.state.internshipDetailsArray, add],
    });
  }

  removeField(e, index) {
    e.preventDefault();
    let internshipDetailsArray = [...this.state.internshipDetailsArray];
    internshipDetailsArray.splice(index, 1);
    this.setState({ internshipDetailsArray });
  }

  render() {
    const { internshipDetailsArray } = this.state;

    return (
      <div className="form-container">
        <h1 className="mb-5">Internship Details</h1>
        {internshipDetailsArray.map((detail, index) => {
          return (
            <div key={index}>
              <hr />
              <h4>Project</h4>
              <div className="form-group">
                <label htmlFor={`title${index}`}>Title</label>
                <input
                  type="text"
                  placeholder="smart agriculture"
                  className="form-control"
                  id={`title${index}`}
                  name="title"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].title}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`description${index}`}>Description</label>
                <textarea
                  type="text"
                  placeholder="type text"
                  className="form-control"
                  id={`description${index}`}
                  name="description"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].description}
                />
              </div>
              <h6>Duration</h6>
              <div className="form-group">
                <label htmlFor={`duration_from${index}`}>Start Date</label>
                <input
                  type="text"
                  className="form-control"
                  id={`duration_from${index}`}
                  name="duration_from"
                  placeholder="July 2020"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].duration_from}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`duration_to${index}`}>End Date</label>
                <input
                  type="text"
                  className="form-control"
                  id={`duration_to${index}`}
                  name="duration_to"
                  placeholder="August 2020"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].duration_to}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`technologies${index}`}>
                  Technologies Used
                </label>
                <input
                  type="text"
                  placeholder="HTML, CSS, Reactjs"
                  className="form-control"
                  id={`technologies${index}`}
                  name="technologies"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].technologies}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`reference${index}`}>Reference</label>
                <input
                  type="text"
                  placeholder="www.google.co.in"
                  className="form-control"
                  id={`reference${index}`}
                  name="reference"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].reference}
                />
              </div>
              <br />
              <h5>Guide Details</h5>
              <div className="form-group">
                <label htmlFor={`guide_name${index}`}>Name</label>
                <input
                  type="text"
                  placeholder="full name"
                  className="form-control"
                  id={`guide_name${index}`}
                  name="guide_name"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].guide_name}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`designation${index}`}>Designation</label>
                <input
                  type="text"
                  placeholder="job"
                  className="form-control"
                  id={`designation${index}`}
                  name="designation"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].designation}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`company${index}`}>Institue / Company</label>
                <input
                  type="text"
                  placeholder="example: NITW"
                  className="form-control"
                  id={`company${index}`}
                  name="company"
                  onChange={(e) => this.inputChange(e, index)}
                  value={internshipDetailsArray[index].company}
                />
              </div>
              {index !== 0 && (
                <button
                  className="remove-field"
                  onClick={(e) => this.removeField(e, index)}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
        <br />
        <button className="add-field" onClick={(e) => this.addField(e)}>
          Add Internship
        </button>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { internshipDetailsAction })(
  InternshipDetails
);
