import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";

import Recaptcha from "react-google-recaptcha";
import { userAuthDetailsAction } from "../../store/actions";

import "./UserAuth.css";
import { emailValidation } from "../../helpers/common";
import { loginUser } from "../../services/Auth.service";
import { toast, ToastContainer } from "react-toastify";
import { setAuthorizationHeader } from "../../utils/axios.util";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      loginUsername: "",
      loginPassword: "",
      loginUsernameNotValid: true,
      showLoginError: false,
      errorMessage: "Invalid Credentials",
      isVerified: false,
      isLoaded: false,
      selectCaptcha: true,
      showAlert: false,
      showLoader: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.loadCallback = this.loadCallback.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    const { userAuthDetails, history } = this.props;
    if (userAuthDetails?.token) history.push("/dashboard");
  }

  componentWillUnmount() {
    document.getElementById("recaptcha").remove();
  }

  verifyCallback(response) {
    const { isLoaded } = this.state;
    if (response && isLoaded)
      this.setState({ isVerified: true });
  }

  loadCallback(response) {
    const { loaded } = response;

    this.setState({ isLoaded: loaded });
  }

  handleOnClick(e) {
    e.preventDefault();
    const { loginPassword, loginUsername } = this.state;
    const { history } = this.props;
    if (!loginUsername) {
      document.getElementById("loginUsername").focus();
    } else if (emailValidation(loginUsername)) {
      this.setState({ loginUsernameNotValid: false });
      if (!loginPassword) {
        document.getElementById("loginPassword").focus();
      } else {
        if (this.state.isVerified) {
          this.setState({
            showLoader: true,
          });
          const thisRef = this;
          loginUser({
            email: loginUsername,
            password: loginPassword
          })
            .then((data) => {
              setAuthorizationHeader(data.data.token);
              thisRef.setState({
                showLoader: false,
              });
              thisRef.props.userAuthDetailsAction(data.data);
              window.document.body.style.overflow = "auto";
              history.push("/dashboard");
            })
            .catch(function (error) {
              window.document.body.style.overflow = "auto";
              thisRef.setState({
                showLoader: false,
              });
              // thisRef.setState({ showAlert: true });
              toast.error(error.message || 'Failed to login', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
              });
            });
        } else {
          this.setState({ selectCaptcha: false });
        }
      }
    }
  }

  handleOnChange(e) {
    const { value, name } = e.target;

    switch (name) {
      case "loginUsername": {
        if (!emailValidation(value)) {
          this.setState({ loginUsernameNotValid: true });
        } else {
          this.setState({ loginUsernameNotValid: false });
        }
        break;
      }
      default:
        break;
    }

    this.setState({
      [name]: value,
    });
  }

  hideAlert() {
    this.setState({ showAlert: false, showLoader: false });
  }

  render() {
    const { toggleView } = this.props;
    const {
      showAlert,
      showLoader,
      showLoginError,
      loginPassword,
      loginUsername,
      loginUsernameNotValid,
      errorMessage,
      selectCaptcha,
    } = this.state;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="cardpadding">
                  <CCardBody>
                    <div className="login-container">
                      {showLoginError && (
                        <span className="error-message">{errorMessage}</span>
                      )}
                      <div className="login-form">
                        <div className="form-group">
                          <br></br>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <i
                                  className="fa fa-user facolor"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              name="loginUsername"
                              placeholder=" Email"
                              id="loginUsername"
                              value={loginUsername}
                              onChange={this.handleOnChange}
                            />
                          </div>
                          {loginUsername && loginUsernameNotValid && (
                            <span className="error-message-text" style={{ color: "red" }}>
                              Please enter valid Email Id
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <br></br>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <i
                                  className="fa fa-lock facolor"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-input"
                              name="loginPassword"
                              placeholder=" Password"
                              id="loginPassword"
                              value={loginPassword}
                              onChange={this.handleOnChange}
                            />
                          </div>
                        </div>
                        <Recaptcha
                          id="recaptcha"
                          sitekey="6LfPwtojAAAAANe6j0JifKgrGiJT1HOQxttpM7vL"
                          render="explicit"
                          onChange={this.verifyCallback}
                          asyncScriptOnLoad={this.loadCallback}
                        />
                        <p style={{ color: "red" }} hidden={selectCaptcha}>
                          Please reconfigure recaptcha
                        </p>
                        &nbsp;
                        {/* <CCol xs="6"> */}
                          <CButton color="link" className="px-0">
                            <span onClick={() => toggleView('forgetPassword')}>
                              Forgot password
                            </span>
                            </CButton>
                        {/* </CCol> */}
                        <CRow>
                          <CCol xs="6">
                            <div
                              className="form-group"
                              onClick={(e) => this.handleOnClick(e)}
                            >
                              <button
                                className="btn1 login-button"
                                disabled={showLoader}
                              >
                                Submit
                                {showLoader && (
                                  <span className="loader">
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </span>
                                )}
                              </button>
                            </div>
                          </CCol>
                          <CCol xs="6" className="text-right">
                            <CButton color="link" className="px-0">
                              <span onClick={() => toggleView("signup")}>
                                New user? Create account
                              </span>
                            </CButton>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
                <CCard className="text-white py-5 d-md-down-none maincards">
                  <CCardBody className="text-center">
                    <div>
                      <h2>Sign In</h2>
                      <p>
                        Land your dream job with the perfect resume employers
                        are looking for!
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
        {showAlert && (
          <SweetAlert
            warning
            title="Invalid Credentials"
            onConfirm={this.hideAlert}
          >
            Please login with valid credentials
          </SweetAlert>
        )}
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails } = state;
  return { userAuthDetails };
};

export default connect(mapStateToProps, { userAuthDetailsAction })(
  withRouter(Login)
);
