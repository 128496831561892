import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { userAuthDetailsAction } from "../store/actions";
import "./SideNavbar.css";

class SideNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
    this.state = { done: false, showAlert: false };
    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidMount() {
    this.setState({ done: false });
  }

  handleChange = (index) => {
    this.props.formChange(index);
  };

  onConfirm() {
    this.setState({ showAlert: false });
  }
  
  render() {
    const { step } = this.props;
    const { done, showAlert } = this.state;
    return (
      <div className="sidenav">
        {showAlert && (
          <SweetAlert
            danger
            title="Are you sure want to logout!"
            onConfirm={this.onConfirm}
            customButtons={
              <React.Fragment>
                <button className="styled-btn" onClick={this.logout}>
                  Log out
                </button>
                <button className="styled-btn" onClick={this.onConfirm}>
                  Close
                </button>
              </React.Fragment>
            }
          ></SweetAlert>
        )}
        <span className="item" onClick={() => this.handleChange(0)}>
          {step >= 1 ? (
            <i className="fas fa-check-circle completecolor"></i>
          ) : (
            <i className="far fa-check-circle"></i>
          )}
          &nbsp; Templates
        </span>
        <span className="item" onClick={() => this.handleChange(1)}>
          {step >= 2 ? (
            <i className="fas fa-check-circle completecolor"></i>
          ) : (
            <i className="far fa-check-circle"></i>
          )}
          &nbsp; Personal Profile
        </span>
        <span className="item" onClick={() => this.handleChange(2)}>
          {step >= 3 ? (
            <i className="fas fa-check-circle completecolor"></i>
          ) : (
            <i className="far fa-check-circle"></i>
          )}
          &nbsp; Education Details
        </span>
        <span className="item" onClick={() => this.handleChange(3)}>
          {step >= 4 ? (
            <i className="fas fa-check-circle completecolor"></i>
          ) : (
            <i className="far fa-check-circle"></i>
          )}
          &nbsp; Internship Work
        </span>
        <span className="item" onClick={() => this.handleChange(4)}>
          {step >= 4 && done ? (
            <i className="fas fa-check-circle completecolor"></i>
          ) : (
            <i className="far fa-check-circle"></i>
          )}
          &nbsp; Personal Projects
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails } = state;
  return { userAuthDetails };
};

export default connect(mapStateToProps, { userAuthDetailsAction })(
  withRouter(SideNavbar)
);
