import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { educationalDetailsAction } from "../store/actions";
import { SAVE_TIME_INTERVAL } from "./constants";

export class EducationalDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      degree: "",
      institute: "",
      intermediate: "",
      college: "",
      school_course: "",
      school: "",
      passOut1: "",
      cgpa1: "",
      passOut2: "",
      cgpa2: "",
      passOut3: "",
      cgpa3: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.updateReduxState = this.updateReduxState.bind(this);
    this.refreshIntervalId = undefined;
  }

  updateReduxState() {
    const data = _.omit(this.state, [""]);
    this.props.educationalDetailsAction(data);
  }

  componentDidMount() {
    const { educationalDetails } = this.props;

    if (!_.isEmpty(educationalDetails)) {
      this.setState(educationalDetails);
    }
    this.refreshIntervalId = setInterval(this.updateReduxState, SAVE_TIME_INTERVAL);
  }

  componentWillUnmount() {
    const data = _.omit(this.state, [""]);
    this.props.educationalDetailsAction(data);
    clearInterval(this.refreshIntervalId);
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      degree,
      institute,
      intermediate,
      college,
      school_course,
      school,
      passOut1,
      passOut2,
      passOut3,
      cgpa1,
      cgpa2,
      cgpa3,
    } = this.state;

    return (
      <div className="form-container">
        <h1 className="mb-5">Educational Details</h1>

        <h4>Higher Education Details</h4>
        <div className="form-group">
          <label htmlFor="degree">Degree</label>
          <input
            type="text"
            placeholder="example:Computer Science Engineering"
            className="form-control"
            id="degree"
            name="degree"
            onChange={(e) => this.inputChange(e)}
            value={degree}
          />
        </div>
        <div className="form-group">
          <label htmlFor="institute">Name of the Institution</label>
          <input
            type="text"
            placeholder="example:IIIT Basar"
            className="form-control"
            id="institute"
            name="institute"
            onChange={(e) => this.inputChange(e)}
            value={institute}
          />
        </div>
        <div className="form-group">
          <label htmlFor="passOut1">Year of Passing Out</label>
          <input
            type="number"
            placeholder="2021"
            className="form-control"
            id="passOut1"
            name="passOut1"
            onChange={(e) => this.inputChange(e)}
            value={passOut1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cgpa1">CGPA</label>
          <input
            type="number"
            placeholder="9.8"
            className="form-control"
            id="cgpa1"
            name="cgpa1"
            onChange={(e) => this.inputChange(e)}
            value={cgpa1}
          />
        </div>
        <br />
        <h4>Intermediate Education Details</h4>
        <div className="form-group">
          <label htmlFor="intermediate">Course</label>
          <input
            type="text"
            placeholder="example:MPC"
            className="form-control"
            id="intermediate"
            name="intermediate"
            onChange={(e) => this.inputChange(e)}
            value={intermediate}
          />
        </div>
        <div className="form-group">
          <label htmlFor="college">Name of the Institution</label>
          <input
            type="text"
            placeholder="example: IIIT Basar"
            className="form-control"
            id="college"
            name="college"
            onChange={(e) => this.inputChange(e)}
            value={college}
          />
        </div>
        <div className="form-group">
          <label htmlFor="passOut2">Year of Passing Out</label>
          <input
            type="number"
            placeholder="2017"
            className="form-control"
            id="passOut2"
            name="passOut2"
            onChange={(e) => this.inputChange(e)}
            value={passOut2}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cgpa2">CGPA</label>
          <input
            type="number"
            placeholder="9.8"
            className="form-control"
            id="cgpa2"
            name="cgpa2"
            onChange={(e) => this.inputChange(e)}
            value={cgpa2}
          />
        </div>
        <br />
        <h4>High School Details</h4>
        <div className="form-group">
          <label htmlFor="school_course">Course</label>
          <input
            type="text"
            placeholder="SSC"
            className="form-control"
            id="school_course"
            name="school_course"
            onChange={(e) => this.inputChange(e)}
            value={school_course}
          />
        </div>
        <div className="form-group">
          <label htmlFor="school">Name of the Institution</label>
          <input
            type="text"
            placeholder="ZPHS"
            className="form-control"
            id="school"
            name="school"
            onChange={(e) => this.inputChange(e)}
            value={school}
          />
        </div>
        <div className="form-group">
          <label htmlFor="passOut3">Year of Passing Out</label>
          <input
            type="number"
            placeholder="2015"
            className="form-control"
            id="passOut3"
            name="passOut3"
            onChange={(e) => this.inputChange(e)}
            value={passOut3}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cgpa3">CGPA</label>
          <input
            type="number"
            placeholder="9.8"
            className="form-control"
            id="cgpa3"
            name="cgpa3"
            onChange={(e) => this.inputChange(e)}
            value={cgpa3}
          />
        </div>

        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { educationalDetailsAction })(
  EducationalDetails
);
