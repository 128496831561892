import { AxiosCall } from "../utils/axios.util";
import env from  '../environments';

export async function signupUser({
  mobile,
  email,
  password,
  name
}) {
  return AxiosCall.post(`${env.baseUrl}/${env.v1}/auth/signup`, {
    mobile,
    email,
    password,
    name
  }) 
}

export async function checkMobileExist({
  mobile
}) {
  return AxiosCall.post(`${env.baseUrl}/${env.v1}/auth/isMobileAvailable`, {
    mobile
  });
}


export async function loginUser({
  email,
  password,
}) {
  return AxiosCall.post(`${env.baseUrl}/${env.v1}/auth/login`, {
    email,
    password,
  }) 
}

export async function resetPassword({
  password,
  confirm,
  reset_code
}) {
  return AxiosCall.post(`${env.baseUrl}/${env.v1}/auth/reset-password`, {
    password,
    confirm,
    reset_code
  }) 
}

export async function forgetPassword({
  email
}) {
  return AxiosCall.post(`${env.baseUrl}/${env.v1}/auth/forgot-password`, {
    email
  }) 
}