import axios from 'axios';

export class AxiosCall {
  static async get(url, source) {
    try {
      if (source) {
        const response = await axios.get(url, {
          cancelToken: source.token
        });
        return response.data;
      } else {
        const response = await axios.get(url);
        return response.data;
      }
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async post(
    url,
    payload,
    config
  ) {
    try {
      const response = await axios.post(url, payload, config);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async put(url, payload) {
    try {
      const response = await axios.put(url, payload);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async delete(url) {
    try {
      const response = await axios.delete(url);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }
}


export const setAuthorizationHeader = (token) => {
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
  }
};

export {axios};