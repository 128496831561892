import React from "react";
import { connect } from "react-redux";
import Form from "./Form";
import SideNavbar from "./SideNavbar";
import Success from "./Success";
import Footer from "./Footer";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { userAuthDetailsAction, stepsCompletedAction } from "../store/actions";
import Profile from "./Profile";
import { UserTypes } from "./constants";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = { step: 0, openProfile: false };
    this.formChange = this.formChange.bind(this);
    this.logout = this.logout.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }

  componentDidMount() {
    const { userAuthDetails, history, step } = this.props;
    if (!userAuthDetails?.token) {
      history.push("/");
      return;
    }
    if (userAuthDetails.user.user_type === UserTypes.ADMIN) {
      history.push("/admin/dashboard")
    }
    setTimeout(() => {
      this.setState({
      step
    })}, 1000)
  }

  formChange = (step) => {
    this.setState({ step });
    this.props.stepsCompletedAction({step})
  };

  logout = () => {
    this.props.userAuthDetailsAction({});
    this.props.history.push("/");
  }

  handleProfile = () => {
    this.setState({
      ...this.state,
      openProfile: !this.state.openProfile,
    })
  }

  render() {
    const { step } = this.state;
    return (
      <div className="App">
        <div className="row">
          <div className="col-lg-12 text-center" style={{ color: "white", display: "flex", justifyContent:"space-between"}}>
            <h1 style={{flexGrow: 4}}>Create My Resume</h1>
            <CDropdown>
              <CDropdownToggle className=".image-cirle-view">
                {this.props?.userAuthDetails?.user?.name ?? 'User'}
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem className="user-item" onClick={this.handleProfile}>Profile</CDropdownItem>
                <CDropdownItem className="user-item" onClick={this.logout}>Logout</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
        </div>
        <div className="row dashboard-container">
          <div
            className="col-lg-2"
            style={{ borderRight: "1px solid gray" }}
          >
            <SideNavbar formChange={this.formChange} step={step} />
          </div>
          <div className="col-lg-5">
            <Form step={step} formChange={this.formChange} />
          </div>
          <div className="col-lg-5">
            <Success step={step} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Footer step={step} formChange={this.formChange} />
          </div>
        </div>
        <Profile open={this.state.openProfile} onClose={this.handleProfile} profile={this?.props?.userAuthDetails?.user} history={this.props.history}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails, stepsCompleted } = state;
  return { userAuthDetails, step: stepsCompleted?.step };
};

export default connect(mapStateToProps, {
  userAuthDetailsAction,
  stepsCompletedAction
})(Dashboard);
