import { combineReducers } from "redux";
import { ACTION_TYPES } from "../actions";

const INITIAL_STATE_PERSONAL = {
  name: "",
  email: "",
  phone: "",
  blog: "",
  position: "",
  branch: "",
  objective: "",
};

const INITIAL_STATE_EDUCATIONAL = {
  degree: "",
  institute: "",
  intermediate: "",
  college: "",
  school_course: "",
  school: "",
  passOut1: "",
  cgpa1: "",
  passOut2: "",
  cgpa2: "",
  passOut3: "",
  cgpa3: "",
};

const INITIAL_STATE_INTERNSHIP = {
  internshipDetailsArray: [
    {
      company: "",
      description: "",
      designation: "",
      duration_from: "",
      duration_to: "",
      guide_name: "",
      title: "",
      reference: "",
      technologies: "",
    },
  ],
};

const INITIAL_STATE_PROJECT = {
  projectDetailsArray: [
    {
      title: "",
      description: "",
      duration_from: "",
      duration_to: "",
      reference: "",
      technologies: "",
    },
  ],
};

const INITIAL_STATE_USER = {
  token: '',
  user: {}
};

const personalDetailsReducer = (state = INITIAL_STATE_PERSONAL, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.PERSONAL_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const educationalDetailsReducer = (
  state = INITIAL_STATE_EDUCATIONAL,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.EDUCATIONAL_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const internshipDetailsReducer = (state = INITIAL_STATE_INTERNSHIP, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.INTERNSHIP_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const projectDetailsReducer = (state = INITIAL_STATE_PROJECT, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.PROJECT_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const userAuthDetailsReducer = (state = { INITIAL_STATE_USER }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.USER_AUTH_DETAILS: {
      if (!payload || !Object.keys(payload).length) {
        return {
          ...state,
          token: '',
          user: {}
        };
      }
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const selectTemplateReducer = (state = { id: 1 }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SELECTED_TEMPLATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const pdfUploadReducer = (state = { pdf: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.PDF_UPLOAD:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const stepsCompletedReducer = (state = { step: 0 }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.STEPS_COMPLETED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  personalDetails: personalDetailsReducer,
  educationalDetails: educationalDetailsReducer,
  internshipDetails: internshipDetailsReducer,
  projectDetails: projectDetailsReducer,
  userAuthDetails: userAuthDetailsReducer,
  selectedTemplate: selectTemplateReducer,
  pdfUpload: pdfUploadReducer,
  stepsCompleted: stepsCompletedReducer
});
