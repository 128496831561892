import React from "react";

import "./Footer.css";
import { rguktLogo } from "../images";
import { CLink } from "@coreui/react";

class Footer extends React.Component {
  nextStep = () => {
    const { step } = this.props;
    this.props.formChange(step + 1);
  };

  prevStep = () => {
    const { step } = this.props;
    this.props.formChange(step - 1);
  };
  render() {
    const { step } = this.props;
    return (
      <div
        style={{ backgroundColor: "white", borderTop: "1px solid gray" }}
        className="footer row"
      >
        <div className="col-lg-4">
          <a
            href="https://www.rgukt.ac.in/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={rguktLogo} width="50px" alt={'logo'} />
          </a>
        </div>
        <div className="col-lg-4">
          <button
            className="btnp"
            onClick={this.prevStep}
            disabled={step === 0}
          >
            Prev
          </button>
          <button
            className="btnn"
            onClick={this.nextStep}
            disabled={step === 4}
          >
            Next
          </button>
        </div>
        <div className="col-lg-4" style={{display: "flex"}}>
          <div>
            <div className="social-buttons" >
              <a
                href="mailto:createresumeforme@gmail.com"
                target="_blank"
                rel="rel noopener noreferrer"
                style={{ marginLeft: "-50px" }}
              >
                <i className="fas fa-envelope"></i>
              </a>
            </div>
            <div><CLink style={{ fontSize: "14px", marginLeft: "-50px", color: "black" }}>createresumeforme@gmail.com</CLink></div>
          </div>
          <div>
            <div className="social-buttons" >
              <a
                href="https://wa.me/919100125951/?text=Hi,%20this%20is%20"
                target="_blank"
                rel="rel noopener noreferrer"
                style={{ marginLeft: "50px" }}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
            <div><CLink style={{ fontSize: "14px", marginLeft: "40px", color: "black" }}>919491486951</CLink></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
