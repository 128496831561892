import React, { Component } from "react";

import Templates from "./Templates";
import PersonalDetails from "./PersonalDetails";
import EducationalDetails from "./EducationalDetails";
import IntershipDetails from "./InternshipDetails";
import ProjectDetails from "./ProjectDetails";
// import Confirm from "./Confirm";
// import Success from "./Success";

import "./Form.css";

class Form extends Component {
  nextStep = () => {
    const { step } = this.props;
    this.props.formChange(step + 1);
  };

  prevStep = () => {
    const { step } = this.props;
    this.props.formChange(step - 1);
  };

  render() {
    const { step } = this.props;

    switch (step) {
      case 0:
        return <Templates nextStep={this.nextStep} />;
      case 1:
        return (
          <PersonalDetails nextStep={this.nextStep} prevStep={this.prevStep} />
        );
      case 2:
        return (
          <EducationalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        );
      case 3:
        return (
          <IntershipDetails nextStep={this.nextStep} prevStep={this.prevStep} />
        );
      case 4:
        return (
          <ProjectDetails nextStep={this.nextStep} prevStep={this.prevStep} />
        );
      default:
        return <PersonalDetails nextStep={this.nextStep} />;
    }
  }
}

export default Form;
