import React from 'react';
import DefaultImg from '../assets/upload_blue.svg';
const DefaultProfile = (props) => {
  return (
    <img
      src={DefaultImg}
      {...props}
      style={{
        flex: 1,
        display: 'flex',
        height: '5rem',
        width: '5rem',
        borderRadius: '50%'
      }}
      alt={props.alt ?? ''}
    />
  );
};
export default DefaultProfile;
