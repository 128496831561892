import localEnvVariables from './env.local';
import prodEnvVariables from './env.prod';

let envVariables = {
  baseUrl: '',
  v1: ''
};

if (process.env.REACT_APP_STAGE === 'production') { //development //production
  envVariables = prodEnvVariables;
} else {
  envVariables = localEnvVariables;
}

export default envVariables;