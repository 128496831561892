import React, { Component } from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";

import "./ResetPassword.css";
import { passwordValidation } from "../../helpers/common";
import { resetPassword } from "../../services/Auth.service";
import { toast, ToastContainer } from "react-toastify";
import { TOASTER_STYLE } from "../constants";
import { userAuthDetailsAction } from "../../store/actions";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      new_password_error: '',
      confirm_password_error: '',
      payload: {
        confirm: '',
        password: '',
        reset_code: ''
      },
      loader: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    const { location, history, userAuthDetails } = this.props;
    if (userAuthDetails?.token) {
      history.push("/dashboard");
      return;
    }
    const resetCode = new URLSearchParams(location.search).get("reset_code");
    this.setState({
      payload: {
        ...this.state.payload,
        reset_code: resetCode
      }
    });
  }

  validateForm() {
    const { password, confirm } = this.state.payload;
    let validationFailed = false;
    if (!password) {
      this.setState({
        new_password_error: 'New Password is required'
      });
      validationFailed = true;
    }
    if (!confirm) {
      this.setState({
        confirm_password_error: 'Confirm Password is required'
      });
      validationFailed = true;
    }
    if (password !== confirm) {
      this.setState({
        confirm_password_error: 'Confirm Password is matching'
      });
      validationFailed = true;
    }
    return validationFailed;  
  }

  handleSubmit(e) {
    e.preventDefault();
    const {history} = this.props;
    if (this.validateForm()) {
      return;
    }
    this.setState({ loader: true })
    resetPassword(this.state.payload)
      .then((res) => {
        toast.success(res.message || 'Password updated.', TOASTER_STYLE);
        this.setState({ loader: false })
        history.push('/')
      }).catch((err) => {
        toast.error(err.message || 'Failed to reset the password.', TOASTER_STYLE);
      }).finally(() => {
        this.setState({ loader: false })
      })
  }

  handleOnChange(e) {
    const { value, name } = e.target;

    switch (name) {
      case "password": {
        if (passwordValidation(value)) {
          this.setState({ new_password_error: '' });
        } else {
          this.setState({ new_password_error: 'Password should contain 8 characters, one alphabet, one digit and one special character.' });
        }
        break;
      }
      case "confirm": {
        if (value === this.state.payload.password) {
          this.setState({ confirm_password_error: '' });
        } else {
          this.setState({ confirm_password_error: 'Confirm password is not matching' });
        }
        break;
      }
      default:
        break;
    }

    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      }
    });
  }

  render() {
    const {
      payload,
      new_password_error,
      confirm_password_error,
      loader
    } = this.state;
    return (
      <div className="container-fluid auth-page-container">
        <div className="main">
          <h2 style={{ textAlign: "center", color: "white" }}>#CreateResumeForMe</h2>
          <div className="signin">
            <div className="c-app c-default-layout flex-row align-items-center">
              <CContainer>
                <CRow className="justify-content-center">
                  <CCol md="8">
                    <CCardGroup>
                      <CCard className="cardpadding">
                        <CCardBody>
                          <div className="login-container">
                            <div className="login-form">
                              <div className="form-group">
                                <br></br>
                                New Password*
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <i
                                        className="fa fa-lock facolor"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                  <input
                                    type="password"
                                    className="form-control form-input"
                                    name="password"
                                    placeholder="Enter New Password"
                                    id="loginPassword"
                                    value={payload.password}
                                    onChange={this.handleOnChange}
                                  />
                                </div>
                                {new_password_error && (
                                  <span className="error-message-text" style={{ color: "red" }}>
                                    {new_password_error}
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <br></br>
                                Confirm Password*
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <i
                                        className="fa fa-lock facolor"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                  <input
                                    type="password"
                                    className="form-control form-input"
                                    name="confirm"
                                    placeholder="Enter Confirm Password"
                                    id="confirm"
                                    value={payload.confirm}
                                    onChange={this.handleOnChange}
                                  />
                                </div>
                                {confirm_password_error && (
                                  <span className="error-message-text" style={{ color: "red" }}>
                                    {confirm_password_error}
                                  </span>
                                )}
                              </div>
                              &nbsp;
                              <CRow>
                                <CCol xs="8">
                                  <div
                                    className="form-group"
                                    onClick={(e) => this.handleSubmit(e)}
                                  >
                                    <button
                                      className="reset-button"
                                      disabled={loader}
                                    >
                                      Reset Password
                                      {loader && (
                                        <span className="loader">
                                          <i className="fa fa-spinner fa-spin"></i>
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </CCol>
                              </CRow>
                            </div>
                          </div>
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userAuthDetails, stepsCompleted } = state;
  return { userAuthDetails, step: stepsCompleted?.step };
};

export default connect(mapStateToProps, {
  userAuthDetailsAction
})(ResetPassword);
