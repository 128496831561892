import React from "react";
import { connect } from "react-redux";
import { Container, Card, CardColumns } from "react-bootstrap";

import { selectTemplateAction } from "../store/actions";
import { Resume1, Resume2 } from "../images";

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.selectTemplate = this.selectTemplate.bind(this);
  }
  selectTemplate(number) {
    const data = {
      id: number,
    };
    this.props.selectTemplateAction(data);
  }
  render() {
    return (
      <div className="templates-container">
        <Container>
          <CardColumns>
            <Card className="card-item">
              <Card.Img
                variant="top"
                src={Resume1}
                className="img-template"
                onClick={() => this.selectTemplate(1)}
              />
              <Card.Text className="text-center" style={{ color: "black" }}>
                Template 1
              </Card.Text>
            </Card>
            <Card className="card-item">
              <Card.Img
                variant="top"
                src={Resume2}
                className="img-template"
                onClick={() => this.selectTemplate(2)}
              />
              <Card.Text className="text-center" style={{ color: "black" }}>
                Template 2
              </Card.Text>
            </Card>
          </CardColumns>
        </Container>
      </div>
    );
  }
}

export default connect(null, { selectTemplateAction })(Templates);
