import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UserTypes } from "../constants";
import ForgetPassword from "./ForgetPassword";
import Login from "./Login";
import SignUp from "./SignUp";
import "./UserAuth.css";

class AuthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: "login",
    };
    this.toggleView = this.toggleView.bind(this);
  }

  componentDidMount() {
    this.props.history.push('?' + this.state.activeView)
    const { userAuthDetails, history } = this.props;
    console.log(userAuthDetails)
    if (userAuthDetails?.token) {
      if (userAuthDetails.user.user_type === UserTypes.ADMIN) {
        history.push("/admin/dashboard")
      } else {
        history.push("/dashboard")
      }
    }
   
  }

  toggleView(activeView) {
    this.props.history.push('?' + activeView)
    this.setState({
      activeView,
    });
  }

  shouldComponentUpdate(nextProps){
    const activeView = nextProps.location.search.replace('?', '');
    if (activeView !== this.state.activeView) {
      this.setState({
        activeView,
      });
    }
    return activeView !== this.state.activeView
  }

  render() {
    const { activeView } = this.state;
    return (
      <div className="container-fluid auth-page-container">
        <div className="main">
          <h2 style={{ textAlign: "center",color:"white" }}>#CreateResumeForMe</h2>
          <div className="signin">
            {(activeView === "login" || activeView === "signup" || activeView === 'forgetPassword') && (
              <div
                style={{
                  transform: "uppercase",
                  color: "white",
                  cursor: "pointer",
                  textAlign:"center",
                }}
              >
              </div>
            )}
            {activeView === "login" && <Login toggleView={this.toggleView} />}
            {activeView === "signup" && <SignUp toggleView={this.toggleView} />}
            {activeView === "forgetPassword" && <ForgetPassword toggleView={this.toggleView} />}
          </div>
          <h4 style={{color:"white",textAlign:"center"}}>Best viewed in Desktop only</h4>
        </div>
        {/* <br></br> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {})(withRouter(AuthPage));
