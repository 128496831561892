import { AxiosCall } from "../utils/axios.util";
import env from  '../environments';

export async function updateMyProfileDetails({
  mobile,
  name,
  profile_image
}) {
 return AxiosCall.put(`${env.baseUrl}/${env.v1}/me/profile`, {
    mobile,
    name,
    profile_image
  }) 
}

export async function getMyProfileDetails(){
  return AxiosCall.get(`${env.baseUrl}/${env.v1}/me/profile`) 
}
