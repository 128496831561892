import React from "react";
import ReactDOM from "react-dom";
// import { createStore } from "redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as firebase from "firebase";

import App from "./components/App";
import { firebaseConfig } from "./components/constants/index";
import { configureStore } from "./store";
import { BrowserRouter } from "react-router-dom";

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().languageCode = "en-US";

const { store, persistor } = configureStore();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.querySelector("#root")
);
